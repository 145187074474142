
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'ModelObjectParentUpdateDialog'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly form!: IVForm

  currentObjectHandleIds: string[] | null = null

  searchModel = ''
  progress = 0

  formController = new FormController({
    initialModel: {
      parentId: null as string | null
    },
    validationRules: {
      parentId: validationRules.exists
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get objectHandleIds () {
    return this.$queryArray('object_parent_update_dialog')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get objects () {
    const currentObjectHandleIds = this.currentObjectHandleIds
    return Object.values(this.modelModule.objects).filter(o => currentObjectHandleIds?.includes(o.handleId))
  }

  get objectNames () {
    return this.objects.map(o => o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`)
  }

  get objectTypes () {
    return [...new Set(this.objects.map(o => o.type))]
  }

  get objectTypesMatch () {
    return (
      this.objectTypes.every(o => o === 'root') ||
      this.objectTypes.every(o => o === 'system' || o === 'actor' || o === 'group') ||
      this.objectTypes.every(o => o === 'app' || o === 'store') ||
      this.objectTypes.every(o => o === 'component')
    )
  }

  get affectedObjects () {
    return [
      ...this.objects,
      ...this.objects.map(o => Object.values(this.modelModule.objects).filter(e => e.parentIds.includes(o.id))).flat()
    ]
  }

  get affectedObjectNames () {
    return this.affectedObjects.map(o => o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`)
  }

  get affectedObjectCount () {
    return this.affectedObjects.length
  }

  get affectedConnections () {
    const objectIds = this.affectedObjects.map(o => o.id)
    return Object
      .values(this.modelModule.connections)
      .filter(o => objectIds.includes(o.originId) || objectIds.includes(o.targetId))
  }

  get affectedConnectionNames () {
    return this.affectedConnections.map(o => o.name).filter(o => o)
  }

  get affectedConnectionCount () {
    return this.affectedConnections.length
  }

  get affectedDiagrams () {
    const diagramIds = [...new Set([
      ...this.affectedObjects.map(o => Object.keys(o.diagrams)).flat(),
      ...this.affectedObjects.map(o => o.childDiagramIds).flat(),
      ...this.affectedConnections.map(o => Object.keys(o.diagrams)).flat()
    ])]
    return diagramIds.map(o => this.diagramModule.diagrams[o])
  }

  get affectedDiagramCount () {
    return this.affectedDiagrams.length
  }

  get affectedDiagramNames () {
    return this.affectedDiagrams
      .sort((a, b) => {
        if (a.modelId === b.modelId) {
          if (a.index === b.index) {
            return a.id > b.id ? -1 : 1
          } else {
            return a.index > b.index ? 1 : -1
          }
        } else {
          return a.name.localeCompare(b.name)
        }
      })
      .map(o => {
        const diagramModel = o ? this.modelModule.objects[o.modelId] : undefined
        if (diagramModel) {
          return diagramModel.type === 'root' ? `${this.domainModule.domains[diagramModel.domainId]?.name} - ${o.name}` : o.name
        } else {
          return null
        }
      })
      .filter((o): o is string => !!o)
  }

  get multipleParents () {
    return this.parents.length > 1
  }

  get parents () {
    return this.parentIds.map(o => {
      const object = this.modelModule.objects[o]
      const domain = this.domainModule.domains[o]
      if (object) {
        return object
      } else {
        return domain
      }
    })
  }

  get parentNames () {
    return this.parentIds.map(o => {
      const object = this.modelModule.objects[o]
      const domain = this.domainModule.domains[o]
      if (object) {
        return object.name || `${object.type.slice(0, 1).toUpperCase()}${object.type.slice(1)}`
      } else {
        return domain.name
      }
    })
  }

  get parentIds () {
    const parentIds = this.objects.map(o => {
      const parent = o.parentId ? this.modelModule.objects[o.parentId] : null
      if (parent?.type === 'root') {
        return parent.domainId
      } else if (parent) {
        return parent.id
      } else {
        return null
      }
    }).filter((o): o is string => !!o)
    return [...new Set(parentIds)]
  }

  get parentOptions () {
    const parentIds = this.parentIds
    const objectTypes = this.objectTypes
    return Object
      .values(this.modelModule.objects)
      .filter(o => {
        if (objectTypes.includes('system') || objectTypes.includes('actor') || objectTypes.includes('group')) {
          return o.type === 'root'
        } else if (objectTypes.includes('app') || objectTypes.includes('store')) {
          return o.type === 'system'
        } else if (objectTypes.includes('component')) {
          return o.type === 'app' || o.type === 'store'
        } else {
          return false
        }
      })
      .map(o => ({
        ...o,
        disabled: parentIds.length === 1 && (o.type === 'root' ? o.domainId === parentIds[0] : o.id === parentIds[0]),
        icon: o.icon ? iconUrlForTheme(o.icon) : null,
        name: o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name,
        text: o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`,
        type: o.type === 'root' ? 'Domain' : `${o.type} in: ${this.domainModule.domains[o.domainId]?.name}`
      }))
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        } else if (a.name && !b.name) {
          return -1
        } else if (!a.name && b.name) {
          return 1
        } else {
          return a.type.localeCompare(b.type)
        }
      })
  }

  get parent () {
    return this.parents.find(o => o) || null
  }

  get parentName () {
    return this.parentNames.find(o => o) || null
  }

  get parentIcon () {
    return this.parent && 'type' in this.parent && this.parent.icon ? iconUrlForTheme(this.parent.icon) : undefined
  }

  get newParent () {
    return this.formController.model.parentId ? this.modelModule.objects[this.formController.model.parentId] : undefined
  }

  get newParentIcon () {
    return this.newParent?.icon ? iconUrlForTheme(this.newParent.icon) : undefined
  }

  created () {
    this.formController.submitHandler = async model => {
      if (!model.parentId) {
        throw new Error('Parent not found')
      }
      if (this.currentDiagram?.status === 'draft') {
        throw new Error('Not available in draft')
      }

      this.editorModule.resetTaskLists()

      const objects = this.objects.filter(o => o.parentId !== model.parentId)

      const progressStep = 100 / objects.length
      this.progress = 0

      for (const object of objects) {
        const prevObject = window.structuredClone(object)

        const { objectUpdate } = this.modelModule.generateObjectCommit(object.id, {
          parentId: model.parentId
        })
        await this.modelModule.objectUpdate({
          landscapeId: this.currentLandscapeId,
          objectId: object.id,
          props: objectUpdate,
          versionId: this.currentVersionId
        })

        this.editorModule.resetTaskLists()

        analytics.modelObjectUpdate.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectDescriptionLength: prevObject.description?.length || 0,
          modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
          modelObjectExternal: prevObject.external,
          modelObjectIconName: prevObject.icon?.name || null,
          modelObjectLinkCount: Object.keys(prevObject.links).length,
          modelObjectNameLength: prevObject.name.length,
          modelObjectParent: prevObject.parentId,
          modelObjectStatus: prevObject.status,
          modelObjectTagCount: prevObject.tagIds.length,
          modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
          modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
          modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
          modelObjectType: prevObject.type,
          modelObjectUpdateParent: objectUpdate.parentId,
          organizationId: [this.currentLandscape.organizationId]
        })

        this.progress += progressStep
      }

      this.progress = 100
    }
    this.formController.on('success', async () => {
      if (this.currentDiagram) {
        await this.diagramModule.diagramContentFind({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
      }

      this.$replaceQuery({
        object_parent_update_dialog: undefined
      })
    })
  }

  mounted () {
    this.currentObjectHandleIds = this.objectHandleIds
  }

  open () {
    this.currentObjectHandleIds = this.objectHandleIds
  }

  opened () {
    analytics.modelObjectParentUpdateDialog.track(this, {
      landscapeId: [this.currentLandscapeId],
      modelObjectTypes: this.objectTypes,
      organizationId: [this.currentOrganizationId]
    })
  }

  closed () {
    this.searchModel = ''

    this.formController.resetModel()
    this.formController.resetStatus()
  }

  searchFilter (item: ModelObject, search: string) {
    return (
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.type.includes(search.toLowerCase()) ||
      item.caption?.includes(search.toLowerCase())
    )
  }
}
