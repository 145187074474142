
import { TAG_COLOR_ACTIVE, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { ModelStatus } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import statusIcons from '../../helpers/status-icon'

@Component({
  components: {
    TagChip
  },
  name: 'ModelStatusFilterListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly status!: ModelStatus

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get filterStatus () {
    return this.$queryArray('filter')
  }

  get filterExcludeStatus () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.filterStatus.length || this.filterExcludeStatus.length) && !this.filtered && !this.excluded ? 0.5 : 1
  }

  get colorMap () {
    return (this.filterStatus.length || this.filterExcludeStatus.length) && (this.filtered || this.excluded) ? TAG_COLOR_ACTIVE : TAG_COLOR_INACTIVE
  }

  get icon () {
    return statusIcons[this.status.type]
  }

  get filtered () {
    return this.filterStatus.includes(this.status.id)
  }

  get excluded () {
    return this.filterExcludeStatus.includes(this.status.id)
  }

  toggleStatusFilter () {
    if (this.filtered) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.status.id),
        filter_exclude: this.$removeQueryArray(this.status.id)
      })
    } else {
      this.$replaceQuery({
        filter: {
          ...this.$unionQueryArray(this.status.id),
          ...this.$removeQueryArray(...this.filterStatus.filter(o => o.startsWith('status-')))
        },
        filter_exclude: this.$removeQueryArray(this.status.id)
      })

      analytics.modelStatusFilter.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelStatus: this.status.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  toggleStatusExclude () {
    if (this.excluded) {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.status.id),
        filter_exclude: this.$removeQueryArray(this.status.id)
      })
    } else {
      this.$replaceQuery({
        filter: this.$removeQueryArray(this.status.id),
        filter_exclude: this.$unionQueryArray(this.status.id)
      })

      analytics.modelStatusFilterExclude.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelStatus: this.status.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
