
import { ModelObjectType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { IFilterType } from '../../helpers/filters'

@Component({
  name: 'ModelTypeFilterListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly type!: IFilterType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get filterType () {
    return this.$queryArray('filter')
  }

  get filterIncludeType () {
    return this.$queryArray('filter_include')
  }

  get filterExcludeType () {
    return this.$queryArray('filter_exclude')
  }

  get opacity () {
    return (this.filterType.length || this.filterIncludeType.length || this.filterExcludeType.length) && !this.filtered && !this.included && !this.excluded ? 0.5 : 1
  }

  get icon () {
    return this.type.icon
  }

  get filtered () {
    return this.filterType.includes(this.type.id)
  }

  get included () {
    return this.filterIncludeType.includes(this.type.id)
  }

  get excluded () {
    return this.filterExcludeType.includes(this.type.id)
  }

  toggleTypeFilter () {
    if (this.type.id.startsWith('external')) {
      if (this.filtered) {
        this.$replaceQuery({
          filter: this.$removeQueryArray(this.type.id),
          filter_exclude: this.$removeQueryArray(this.type.id)
        })
      } else {
        this.$replaceQuery({
          filter: {
            ...this.$unionQueryArray(this.type.id),
            ...this.$removeQueryArray(...this.filterType.filter(o => o.startsWith('external-')))
          },
          filter_exclude: this.$removeQueryArray(this.type.id)
        })

        analytics.modelExternalFilter.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectExternal: this.type.id === 'external-true',
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    } else if (this.type.id.startsWith('type')) {
      if (this.included) {
        this.$replaceQuery({
          filter_exclude: this.$removeQueryArray(this.type.id),
          filter_include: this.$removeQueryArray(this.type.id)
        })
      } else {
        this.$replaceQuery({
          filter_exclude: this.$removeQueryArray(this.type.id),
          filter_include: this.$unionQueryArray(this.type.id)
        })

        analytics.modelTypeFilterInclude.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectType: this.type.id.replace('type-', '') as ModelObjectType,
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }

  toggleTypeExclude () {
    if (this.type.id.startsWith('external')) {
      if (this.excluded) {
        this.$replaceQuery({
          filter: this.$removeQueryArray(this.type.id),
          filter_exclude: this.$removeQueryArray(this.type.id),
          filter_include: this.$removeQueryArray(this.type.id)
        })
      } else {
        this.$replaceQuery({
          filter: this.$removeQueryArray(this.type.id),
          filter_exclude: {
            ...this.$unionQueryArray(this.type.id),
            ...this.$removeQueryArray(...this.filterExcludeType.filter(o => o.startsWith('external-')))
          },
          filter_include: this.$removeQueryArray(this.type.id)
        })

        analytics.modelExternalFilterExclude.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectExternal: this.type.id === 'external-true',
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    } else if (this.type.id.startsWith('type')) {
      if (this.excluded) {
        this.$replaceQuery({
          filter: this.$removeQueryArray(this.type.id),
          filter_exclude: this.$removeQueryArray(this.type.id),
          filter_include: this.$removeQueryArray(this.type.id)
        })
      } else {
        this.$replaceQuery({
          filter: this.$removeQueryArray(this.type.id),
          filter_exclude: this.$unionQueryArray(this.type.id),
          filter_include: this.$removeQueryArray(this.type.id)
        })

        analytics.modelTypeFilterExclude.track(this, {
          landscapeId: [this.currentLandscape.id],
          modelObjectType: this.type.id.replace('type-', '') as ModelObjectType,
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }
}
