
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { ModelModule } from '@/modules/model/store'

import { externalFilters, typeFilters } from '../../helpers/filters'
import ListItem from './list-item.vue'

@Component({
  components: {
    ListItem
  },
  name: 'ModelTypeFilter'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  searchTerm = ''
  searchTermModel = ''

  types = [
    ...externalFilters,
    ...typeFilters
  ]

  get typesFuzzy () {
    if (this.searchTerm) {
      const fuse = new Fuse(this.types, {
        keys: [
          'name'
        ],
        threshold: 0.3
      })
      return fuse.search(this.searchTerm).map(o => o.item)
    } else {
      return this.types
    }
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }
}
