
import { ModelConnection, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { ModelModule } from '@/modules/model/store'

import ModelObjectEditableByMenu from './object-editable-by-menu.vue'

@Component({
  components: {
    ModelObjectEditableByMenu
  },
  name: 'ModelObjectEditableBy'
})
export default class extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly object?: ModelObject | ModelObject[]
  @Prop() readonly connection?: ModelConnection | ModelConnection[]
  @Prop() readonly permission?: PermissionType
  @Prop() readonly isProtected?: boolean

  get objects (): ModelObject[] {
    return this.object instanceof Array ? this.object : this.object ? [this.object] : []
  }

  get connections (): ModelConnection[] {
    return this.connection instanceof Array ? this.connection : this.connection ? [this.connection] : []
  }

  get connectionObjects () {
    return this.connections.map(o => this.modelModule.objects[o.originId])
  }

  get allEditorsActive (): boolean {
    return [...this.objects, ...this.connectionObjects].every(o => !o.teamOnlyEditing)
  }

  get allOwnersOnlyActive (): boolean {
    return [...this.objects, ...this.connectionObjects].every(o => o.teamOnlyEditing)
  }

  get showEditState (): boolean {
    return this.permission !== 'read' && !this.connection && !this.isProtected
  }

  get tooltip () {
    if (this.isProtected && this.object && this.connection) {
      return 'Details are locked to object owners and admins'
    } else if (this.isProtected && this.object) {
      return 'Object details are locked to owners and admins'
    } else if (this.isProtected && this.connection) {
      return 'Connection details are locked to the sending objects owners and admins'
    } else if (this.connection) {
      return 'Property can be changed in the sending object'
    }
  }
}
