
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { Diagram, ModelObject, ModelObjectPartial, ModelObjectType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { objectIcons } from '../../helpers/objects'

@Component({
  components: {
    Dialog
  },
  name: 'ModelObjectTypeUpdateDialog'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly form!: IVForm

  currentObjectHandleId: string | null = null

  searchModel = ''

  formController = new FormController({
    initialModel: {
      parentId: null as string | null,
      type: null as ModelObjectType | null
    },
    validationRules: {
      parentId: validationRules.exists,
      type: validationRules.exists
    }
  })

  iconUrlForTheme = iconUrlForTheme
  objectIcons = objectIcons

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get objectHandleId () {
    return this.$queryValue('object_type_update_dialog')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramObject () {
    return Object.values(this.currentDiagramContent?.objects || {}).find(o => o.modelId === this.object?.id)
  }

  get object () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentObjectHandleId)
  }

  get objects () {
    if (!this.object) {
      return []
    }

    const modelObjectFamily = [this.object, ...Object.values(this.modelModule.objects).filter(c => c.parentIds.includes(this.object!.id))]
    return modelObjectFamily.map(o => ({
      ...o,
      title: o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name || `${o.type.slice(0, 1).toUpperCase()}${o.type.slice(1)}`
    }))
  }

  get objectCount () {
    return this.objects.length
  }

  get connections () {
    const objectIds = this.objects.map(o => o.id)
    return Object
      .values(this.modelModule.connections)
      .filter(o => objectIds.includes(o.originId) || objectIds.includes(o.targetId))
  }

  get connectionCount () {
    return this.connections.length
  }

  get diagrams () {
    if (!this.object) {
      return []
    }

    const diagramIds = [...new Set([
      ...this.objects.map(o => Object.keys(o.diagrams)).flat(),
      ...this.objects.map(o => o.childDiagramIds).flat(),
      ...this.connections.map(o => Object.keys(o.diagrams)).flat()
    ])]
    return diagramIds
      .map(o => {
        const diagram = this.diagramModule.diagrams[o]
        const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
        if (diagramModel) {
          const title = diagramModel.type === 'root' ? `${this.domainModule.domains[diagramModel.domainId]?.name} - ${diagram.name}` : diagram.name
          return {
            ...diagram,
            title
          }
        } else {
          return null
        }
      })
      .filter((o): o is Diagram & { title: string } => !!o)
      .sort((a, b) => {
        if (a.modelId === b.modelId) {
          if (a.index === b.index) {
            return a.id > b.id ? -1 : 1
          } else {
            return a.index > b.index ? 1 : -1
          }
        } else {
          return a.title.localeCompare(b.title)
        }
      })
  }

  get diagramCount () {
    return this.diagrams.length
  }

  get types () {
    const types: { description: string, text: string, icon: string, value: ModelObjectType, disabled: boolean }[] = [
      {
        description: 'Person that gets value from your system(s)',
        disabled: this.object?.type === 'actor',
        icon: objectIcons.actor,
        text: 'Actor',
        value: 'actor'
      },
      {
        description: 'Group of apps/stores, often owned by a team',
        disabled: this.object?.type === 'system',
        icon: objectIcons.system,
        text: 'System',
        value: 'system'
      },
      {
        description: 'Visual grouping or separation of objects',
        disabled: this.object?.type === 'group',
        icon: objectIcons.group,
        text: 'Group',
        value: 'group'
      },
      {
        description: 'Deployable and/or runnable service or client',
        disabled: this.object?.type === 'app',
        icon: objectIcons.app,
        text: 'App',
        value: 'app'
      },
      {
        description: 'Database or storage bucket',
        disabled: this.object?.type === 'store',
        icon: objectIcons.store,
        text: 'Store',
        value: 'store'
      },
      {
        description: 'Building blocks of an app/store',
        disabled: this.object?.type === 'component',
        icon: objectIcons.component,
        text: 'Component',
        value: 'component'
      }
    ]
    return types
  }

  get type () {
    return this.formController.model.type
  }

  get name () {
    if (this.object && this.object.type === 'root') {
      return this.domainModule.domains[this.object.domainId].name
    } else if (this.object && this.object.type !== 'root') {
      return this.object.name || `${this.object.type.substr(0, 1).toUpperCase()}${this.object.type.substr(1)}`
    } else {
      return ''
    }
  }

  get parent () {
    return this.object?.parentId ? this.modelModule.objects[this.object.parentId] : undefined
  }

  get parentLabel () {
    if (this.parent && this.parent.type === 'root') {
      return this.domainModule.domains[this.parent.domainId].name
    } else if (this.parent && this.parent.type !== 'root') {
      return this.parent.name || `${this.parent.type.substr(0, 1).toUpperCase()}${this.parent.type.substr(1)}`
    } else {
      return ''
    }
  }

  get parentEnabled () {
    return !!this.formController.model.type
  }

  get parentOptions () {
    const object = this.object
    if (object) {
      return Object
        .values(this.modelModule.objects)
        .filter(o => {
          if (o.id === object.id) {
            return false
          } else if (this.formController.model.type === 'actor' || this.formController.model.type === 'system' || this.formController.model.type === 'group') {
            return o.type === 'root'
          } else if (this.formController.model.type === 'app' || this.formController.model.type === 'store') {
            return o.type === 'system'
          } else if (this.formController.model.type === 'component') {
            return o.type === 'app' || o.type === 'store'
          } else {
            return false
          }
        })
        .map(o => ({
          ...o,
          name: o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name,
          text: o.type === 'root' ? this.domainModule.domains[o.domainId]?.name : o.name || `${o.type.substr(0, 1).toUpperCase()}${o.type.substr(1)}`,
          type: o.type === 'root' ? 'Domain' : `${o.type} in: ${this.domainModule.domains[o.domainId]?.name}`
        }))
        .sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name)
          } else if (a.name && !b.name) {
            return -1
          } else if (!a.name && b.name) {
            return 1
          } else {
            return a.type.localeCompare(b.type)
          }
        })
    } else {
      return []
    }
  }

  get newParent () {
    return this.formController.model.parentId ? this.modelModule.objects[this.formController.model.parentId] : undefined
  }

  @Watch('parentOptions')
  onParentOptionsChanged (parentOptions: ModelObject[]) {
    if (this.object) {
      const modelObjectFamilyIds = [this.object.id, ...this.modelModule.objects[this.object.id]?.parentIds || []]
      const newParent = parentOptions.find(o => modelObjectFamilyIds.includes(o.id))
      if (newParent) {
        this.formController.model.parentId = newParent.id
      } else {
        this.formController.model.parentId = null
      }
    } else {
      this.formController.model.parentId = null
    }
  }

  created () {
    this.formController.submitHandler = async model => {
      if (!this.object) {
        throw new Error('Object not found')
      }
      if (!model.parentId) {
        throw new Error('Parent not found')
      }
      if (!model.type || model.type === 'root') {
        throw new Error('Type not found')
      }
      if (this.currentDiagram?.status === 'draft') {
        throw new Error('Not available in draft')
      }

      const prevObject = window.structuredClone(this.object)

      const update: Omit<ModelObjectPartial, 'commit'> = {
        type: model.type
      }
      if (model.parentId !== this.object.parentId) {
        update.parentId = model.parentId
      }

      const { objectUpdate } = this.modelModule.generateObjectCommit(this.object.id, update)
      await this.modelModule.objectUpdate({
        landscapeId: this.currentLandscapeId,
        objectId: this.object.id,
        props: objectUpdate,
        versionId: this.currentVersionId
      })

      this.editorModule.resetTaskLists()

      analytics.modelObjectUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectDescriptionLength: prevObject.description?.length || 0,
        modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
        modelObjectExternal: prevObject.external,
        modelObjectIconName: prevObject.icon?.name || null,
        modelObjectLinkCount: Object.keys(prevObject.links).length,
        modelObjectNameLength: prevObject.name.length,
        modelObjectParent: prevObject.parentId,
        modelObjectStatus: prevObject.status,
        modelObjectTagCount: prevObject.tagIds.length,
        modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
        modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
        modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
        modelObjectType: prevObject.type,
        modelObjectUpdateParent: objectUpdate.parentId,
        modelObjectUpdateType: objectUpdate.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
    this.formController.on('success', async () => {
      if (this.currentDiagram) {
        await this.diagramModule.diagramContentFind({
          diagramId: this.currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
      }

      this.$replaceQuery({
        object_type_update_dialog: undefined
      })
    })
  }

  open () {
    this.currentObjectHandleId = this.objectHandleId
  }

  opened () {
    if (this.object) {
      analytics.modelObjectTypeUpdateDialog.track(this, {
        landscapeId: [this.currentLandscapeId],
        modelObjectType: this.object.type,
        organizationId: [this.currentOrganizationId]
      })
    }
  }

  closed () {
    this.currentObjectHandleId = null

    this.searchModel = ''

    this.formController.resetModel()
    this.formController.resetStatus()
  }

  searchFilter (item: ModelObject, search: string) {
    return (
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.type.includes(search.toLowerCase()) ||
      item.caption?.includes(search.toLowerCase())
    )
  }
}
