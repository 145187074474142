
import { ModelObject, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ModelObjectEditableByMenu'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly object?: ModelObject | ModelObject[]

  confirmEditAccessLoss = false
  open = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get objects () {
    return this.object instanceof Array ? this.object : this.object ? [this.object] : []
  }

  get allEditorsActive () {
    return !!this.objects.length && this.objects.every(o => !o.teamOnlyEditing)
  }

  get allOwnersOnlyActive () {
    return !!this.objects.length && this.objects.every(o => o.teamOnlyEditing)
  }

  get alObjectsHaveOwners () {
    return !!this.objects.length && this.objects.every(o => o.teamIds.length)
  }

  get objectsWithNoOwners () {
    return !!this.objects.length && this.objects.filter(o => !o.teamIds.length)
  }

  get ownerCount () {
    return this.objects.map(o => o.teamIds.length).reduce((a, b) => a + b, 0)
  }

  get editAccessLostWhenProtectionEnabled () {
    const teamIds = this.teamModule.userTeams.map(o => o.id)
    return this.currentLandscapePermission !== 'admin' && this.objects.some(o => o.teamIds.every(t => !teamIds.includes(t)))
  }

  resetTaskLists () {
    this.editorModule.resetTaskLists()
  }

  toggleProtection (enable: boolean) {
    if (!this.objects.length) { return }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    this.objects.forEach(o => {
      const prevObject = { ...o }

      revertTasks.push({
        id: o.id,
        props: {
          teamOnlyEditing: o.teamOnlyEditing
        },
        type: 'model-object-update'
      })

      const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, {
        teamOnlyEditing: enable
      })
      this.modelModule.setObjectVersion(object)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.objectUpdate({
          landscapeId: this.currentLandscape.id,
          objectId: o!.id,
          props: objectUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: object.id,
        props: objectUpdate,
        type: 'model-object-update'
      })

      analytics.modelObjectUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectDescriptionLength: prevObject.description?.length || 0,
        modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
        modelObjectExternal: prevObject.external,
        modelObjectIconName: prevObject.icon?.name || null,
        modelObjectLinkCount: Object.keys(prevObject.links).length,
        modelObjectNameLength: prevObject.name.length,
        modelObjectParent: prevObject.parentId,
        modelObjectStatus: prevObject.status,
        modelObjectTagCount: prevObject.tagIds.length,
        modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
        modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
        modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
        modelObjectType: prevObject.type,
        modelObjectUpdateTeamOnlyEditing: objectUpdate.teamOnlyEditing,
        organizationId: [this.currentLandscape.organizationId]
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })

    this.open = false
  }
}
